import axios from "axios";
import {AUTH_LOCALSTORAGE_KEY, SITE_URL} from "../Constants/constants";
import useLocalStorage from "./useLocalStorage";
import {useNavigate} from "react-router-dom";

export type OIUser = {
    id: number,
    name: string,
    email: string,
    role: number,
    consent: boolean
}

export type OIUserData = () => {
    token: string
    user: OIUser
}

const useApi = () => {

    const {set,del,get} = useLocalStorage();
    const navigate = useNavigate();

    const http = axios.create({
        baseURL: SITE_URL,
        headers: {
            "X-Requested-With" : "XMLHttpRequest"
        },
        withCredentials: true
    });

    http.interceptors.response.use(response => response, error => {
        if(error.response.status === 401 || error.response.status === 419){
            logOut();
            return Promise.reject();
        }
        return Promise.reject(error);
    });

    const api = () => http;

    const logIn = (userData: OIUserData) => {
        set(AUTH_LOCALSTORAGE_KEY, userData);
        navigate("/");
    }

    const logOut = () => {
        del(AUTH_LOCALSTORAGE_KEY);
        navigate("/signin");
    }

    const getUser = (): OIUser => {
        const data = get(AUTH_LOCALSTORAGE_KEY);
        if(data) return data.user;
        return null;
    }

    const setConsent = (): OIUser => {
        const data = get(AUTH_LOCALSTORAGE_KEY);
        if(data){
            data.user.consent = true;
            set(AUTH_LOCALSTORAGE_KEY, data);
        }
        return null;
    }

    const downloadFile = (body: string, filename: string, type: string) => {
        const url = window.URL.createObjectURL(new Blob([body],{type}));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', filename); //or any other extension
        document.body.appendChild(link);
        link.click();
    }

    return {api, logIn, logOut, getUser, setConsent,downloadFile};

}

export default useApi;