import {MESSAGE_LOCALSTORAGE_KEY} from "../Constants/constants";

const useLocalStorage = () => {

    const get = (key: string) => {
        const data = window.localStorage.getItem(key);

        if(data) return JSON.parse(data);

        return null;
    };
    const set = (key: string, value: unknown) => window.localStorage.setItem(key, JSON.stringify(value));
    const del = (key: string) => window.localStorage.removeItem(key);

    const setError = (message: string) => {
        set(MESSAGE_LOCALSTORAGE_KEY,{
            message,
            type: "error"
        });
    }
    const setSuccess = (message: string) => {
        set(MESSAGE_LOCALSTORAGE_KEY,{
            message,
            type: "success"
        });
    }
    const getMessage = () => {
        const message = get(MESSAGE_LOCALSTORAGE_KEY);
        del(MESSAGE_LOCALSTORAGE_KEY);
        return message;
    }


    return {get,set,del,setError,setSuccess,getMessage};

};

export default useLocalStorage;