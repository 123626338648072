import React, {useState} from "react";
import AddTokenButton from "./AddTokenButton.component";
import InputField from "./InputField.component";
import {Col, Row} from "react-materialize";


const ValueTokenField = (props: {
    id: string,
    label: string,
    initialValue: string,
    placeholder?: string,
    onAddToken: (token: string) => void,
    onChange: (id: string,value: string) => void
}) => {

    const [value,setValue] = useState<string>("");
    const [showError,setShowError] = useState<boolean>(false);

    const onAddToken = () => {
        const error = value === "" || isNaN(+value);
        setShowError(error );
        if(!error ){
            props.onAddToken(`[[${props.id} ${value}]]`);
        }
    };

    const onChange = (id, value) => {
        setValue(value);
        props.onChange(id,value);
    }

    return <>
        {showError && <Row>
            <Col s={12}>
                <div className="oi-alert -error">Veuillez saisir une valeur numérique</div>
            </Col>
        </Row>}
        <Row>
            <Col s={2}>
                <AddTokenButton id={props.id} onAddToken={onAddToken} type={"valueToken"} />
            </Col>
            <Col s={10}>
                <InputField id={props.id} label={props.label} placeholder={props.placeholder} initialValue={props.initialValue} onChange={onChange} />
            </Col>
        </Row>
    </>;
};

export default ValueTokenField;