import {useNavigate} from 'react-router-dom';
import useApi from "../Hooks/useApi";
import ConsentScreen from "../Pages/Consent.screen";
import {useEffect, useState} from "react";

export { PrivateRoute };

function PrivateRoute({ children }) {
    const {getUser,setConsent, api} = useApi();
    const [hasConsent, setHasConsent] = useState<boolean>(false);
    const navigate = useNavigate();

    useEffect(() =>{
        check();
    }, []);

    const check = () => {
        const user = getUser();
        if (!user) {
            // not logged in so redirect to login page with the return url
            return navigate("/signin");
        }else{
            setHasConsent(user.consent);
        }
    }

    const onConsent = () => {
        api().get(`/sanctum/csrf-cookie`).then(response => {
            return api().get("/api/consent").then((response)=>{
                setConsent();
                setHasConsent(true);
            });
        }).catch((result)=>{
            console.error(result);
        });
    }

    // Vérification du consentement
    if(!hasConsent){
        return <ConsentScreen onConsent={onConsent} />
    }else{
        // authorized so return child components
        return children;
    }


}