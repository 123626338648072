import {OIBilanFieldsValues, OIBilanTokens} from "../Types/Bilan";


const useTextInterpreter = () => {

    const interpretText = (html: string, datas: OIBilanFieldsValues): string => {


        for (let k of OIBilanTokens) {
            if(typeof datas[k] !== "undefined" ){
                const v = datas[k];
                if (k === "ilelle") {
                    html = html.replaceAll('[[ilelle+]]', capitalizeFirstLetter(v));
                    html = html.replaceAll('[[ilelle]]', v);
                } else if (k === "patient") {
                    html = html.replaceAll('[[patient+]]', capitalizeFirstLetter(v));
                    html = html.replaceAll('[[patient]]', v);
                } else if (k !== '') {
                    html = html.replaceAll(`[[${k}]]`, v);
                }
            }
        }

        html = html.replaceAll('[[moyenne]]', '<span style="color:#8fc72e">la moyenne</span>');
        html = html.replaceAll('[[zoneFragilite]]', '<span style="color:#FF9700">la zone de fragilité</span>');
        html = html.replaceAll('[[zonePathologique]]', '<span style="color:#eb4034">la zone pathologique</span>');

        const ecartTypeTokens = html.match(/\[\[ecartType ([-|\+]?)[^\]]+\]\]/gi);
        if (ecartTypeTokens !== null && ecartTypeTokens.length) {
            for (let i = 0; i < ecartTypeTokens.length; i++) {
                const et = ecartTypeTokens[i];
                const value = getEcartType(et);

                if (value !== null) {
                    html = html.replace(et, value);
                }

            }
        }


        const percentileTokens = html.match(/\[\[percentile [^\]]+\]\]/gi);
        if( percentileTokens !== null && percentileTokens.length ){
            for( let i =0; i < percentileTokens.length; i++ ){
                const p = percentileTokens[i];
                const value = getPercentile(p);

                if( value !== null ){
                    html = html.replace(p,value);
                }

            }
        }

        return html;
    }

    const capitalizeFirstLetter = (str: string) => {
        return str.charAt(0).toUpperCase() + str.slice(1);
    }

    const getEcartType = (value: string) => {

        const et = value.match(/([-|\+]?)\d+\.?\d*/);

        if (et && et.length) {

            const ecarType = parseFloat(et[0]);

            let css = '';

            if (ecarType >= -0.99) {
                css = '#8fc72e';
            } else if (-1 >= ecarType && ecarType >= -1.99) {
                css = '#FF9700';
            } else if (ecarType <= -2) {
                css = '#eb4034';
            }

            return '<span style="color:' + css + '">' + ecarType + ' e.t.</span>';
        } else {
            return null;
        }

    }


    const getPercentile = (value: string) => {

        const p = value.match(/\d+\.?\d*/);

        if( p && p.length ){

            const percentile = parseInt(p[0]);

            let css = '';

            if( percentile >= 0 &&  percentile <= 10 ){
                css = '#eb4034';
            }else if( percentile >= 11 &&  percentile <= 25  ){
                css = '#FF9700';
            }else if( percentile > 25 ){
                css = '#8fc72e';
            }

            return '<span style="color:'+css+'">percentile '+percentile+'</span>';
        }else{
            return null;
        }

    }

    return {interpretText}

};

export default useTextInterpreter;