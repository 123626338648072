export type OIBilan = {
    id: string,
    title: string,
    content: string
}

export type OIBilanFieldsValues = {
    nomDocument : string
    nom : string
    patient : string
    ilelle : string
    percentile : string
    ecartType : string,
    title: string
}



export type OIBIlanLocalStorage = {
    id : string,
    title: string,
    content: string,
    date: string
}



export const  OIBilanTokens = [
    "nom",
    "patient",
    "ilelle",
    "percentile",
    "ecartType"
]