import React, {useState} from "react";
import useApi from "../Hooks/useApi";
import {Button, Col, Icon, Row, TextInput} from "react-materialize";
import LogoNoir from "../assets/images/logoNoir.png";

const OILogin = () => {

    const {api,logIn} = useApi();
    const [email,setEmail] = useState<string>("");
    const [password,setPassword] = useState<string>("");
    const [errorMessage, setErrorMessage] = useState<string>("");

    const onSubmit = async (e) => {
        e.preventDefault();

        setErrorMessage("");

        api().get(`/sanctum/csrf-cookie`).then(response => {
            return api().post("/api/login", {email,password}).then((response)=>{
                logIn(response.data);
            });
        }).catch((result)=>{
            if(result.response.data.message)
                setErrorMessage(result.response.data.message);
        });
    }

    return <div className={"login-page"}>

        <form onSubmit={onSubmit} className={"content"}>
            <Row>
                <Col s={8} l={4} offset={"s2 l4"} className={"center-align"}>
                    <img src={LogoNoir} />
                </Col>
            </Row>
            <Row>
                <Col s={8} l={4} offset={"s2 l4"}>
                    <TextInput
                        icon={"email"}
                        label="E-mail"
                        noLayout
                        onChange={(e)=>setEmail(e.target.value)}
                    />
                    {errorMessage && <span className="red-text text-lighten-2">{errorMessage}</span>}
                </Col>
            </Row>
            <Row>
                <Col s={8} l={4} offset={"s2 l4"}>
                    <TextInput
                        icon={"password"}
                        label="Mot de passe"
                        password
                        noLayout
                        onChange={(e)=>setPassword(e.target.value)}
                    />
                </Col>
            </Row>
            <Row>
                <Col s={8} l={4} offset={"s2 l4"} className={"center-align"}>
                    <Button
                        node="button"
                        waves="light"
                        className={"cyan lighten-2"}
                        onClick={onSubmit}
                    >
                        Se connecter
                        <Icon left>
                            login
                        </Icon>
                    </Button>
                </Col>
            </Row>
        </form>

    </div>

}

export default OILogin;