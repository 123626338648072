
import React, {useEffect, useState} from 'react';
import useApi from "../Hooks/useApi";
import {OIBilan} from "../Types/Bilan";
import BilanCard from "../Components/BilanCard.component";
import useLocalStorage from "../Hooks/useLocalStorage";
import {BILAN_LOCALSTORAGE_KEY} from "../Constants/constants";
import {useNavigate} from "react-router-dom";
import {OIAlertMessage} from "../Types/Content";
import AlertComponent from "../Components/Alert.component";
import OIHeader from "../Components/Header.component";
import {Button, Collection, CollectionItem, Icon} from "react-materialize";
import GhostImage from "../assets/images/ghost.png";


const OIBilans = () => {

    const {api} = useApi();
    const {set,getMessage} = useLocalStorage();
    const navigate = useNavigate();
    const [bilans, setBilans] = useState<OIBilan[]>([]);
    const [alertMessage, setAlertMessage] = useState<OIAlertMessage>(null);

    useEffect(()=>{
        getBilansFromApi();
    },[]);

    useEffect(()=>{
        const alertMessage: OIAlertMessage = getMessage();
        if(alertMessage){
            setAlertMessage(alertMessage);
        }
    },[]);

    const getBilansFromApi = async () => {

        try{
            const result = await api().get("/api/templates");

            if(result.data){
                setBilans(result.data.data);
            }
        }catch (e){
            console.error(e);
            showGenericError();
        }
    }

    const onNewTemplate = () => {
        set(BILAN_LOCALSTORAGE_KEY, null);
        navigate(`/editor`);
    }

    const onLoad = (id: string) => {
        navigate(`/editor/${id}`);
    }

    const onDelete = async (id: string) => {
        try{
            if(window.confirm("Etes-vous certain.e de vouloir supprimer cette trame ?")){
                await api().delete(`/api/templates/${id}`);
                getBilansFromApi();
            }
        }catch (e){
            console.error(e);
            showGenericError();
        }
    }

    const showGenericError = () => {
        setAlertMessage({
            message: "Une erreur est survenue",
            type: "error"
        })
    }

    return <>
        <OIHeader />
        {alertMessage && <AlertComponent message={alertMessage.message} type={alertMessage.type} onHide={()=>setAlertMessage(null)}/>}


        <Collection header={bilans.length === 0 ? "" : "Trames"}>
            {bilans.map((b,k)=><BilanCard key={k} bilan={b} onLoad={onLoad} onDelete={onDelete}/>)}

            {bilans.length === 0 && <CollectionItem>
                <div className="oi-trames-empty">
                    <img src={GhostImage} alt="" className="icon"/>
                    <h5  className="title">Aucune trame</h5>
                    <p>Vous n'avez pas encore créé de trame de bilan !</p>
                </div>
            </CollectionItem> }
            <CollectionItem>
                <p>
                    <Button className={"cyan lighten-2"} small onClick={onNewTemplate}>Créer une nouvelle trame</Button>
                </p>
            </CollectionItem>
        </Collection>
    </>
};


export default OIBilans;