import {toJSON} from 'cssjson';

export const useHTML = () => {

    /**
     * Supprime les commentaires du html
     * @param html
     */
    const removeHtmlComments = (html: string) => {
        var reg = new RegExp(
            '<!--[\\s\\S]*?(?:-->)?'
            + '<!---+>?'  // A comment with no body
            + '|<!(?![dD][oO][cC][tT][yY][pP][eE]|\\[CDATA\\[)[^>]*>?'
            + '|<[?][^>]*>?',  // A pseudo-comment
            'g');

        return html.replace(reg,"");
    }

    /**
     * Supprime certains tags html de l'input
     * @param input
     * @param tags
     */
    const cleanHTMLRemovingSpecificTags = (input: string, tags: string[]) => {
        var div = document.createElement('div');
        div.innerHTML = input;
        tags.forEach((tag) => {
            Array.from(div.querySelectorAll(tag)).forEach((el) => el.remove());
        });
        return div.innerHTML.trim();
    };


    /**
     * Nettoie les style css passés en paramètre en ne gardant que les propriétés indiquées
     * @param input
     * @param allowedStyles
     */
    const cleanStyle = (input: string, allowedStyles: string[] = ['color', 'background-color', 'background', 'width']) => {
        const parser = new DOMParser();
        const doc = parser.parseFromString(input, 'text/html');

        const nodes = doc.querySelectorAll('*');
        nodes.forEach(node => {
            const styles = node.getAttribute('style');
            if (styles) {
                const filteredStyles = styles.split(';')
                    .map(style => style.trim())
                    // S'il y a une valeur en rgb, la transforme en hexa
                    .map((style) => {
                        let [property, value] = style.split(':');
                        if(value && value.search("rgb") !== -1){
                            value = rgbToHex(value);
                        }
                        return `${property}:${value}`;
                    })
                    .filter(style => {
                        const [property, _] = style.split(':');
                        return allowedStyles.includes(property);
                    })
                    .join(';');
                node.setAttribute('style', filteredStyles);
            }
            // Suppression des classes
            const classes = node.classList;
            if(classes.length > 0){
                node.removeAttribute("class");
            }
        });

        return doc.documentElement.innerHTML;
    };


    /**
     * Retourne le contenu de la balise style dans le markup
     * @param markup
     */
    const getStyleFromMarkup = (markup: string) => {
        const div = document.createElement("div");
        div.innerHTML = markup;
        const styles = div.querySelectorAll("style");

        let style = "";
        if(styles.length){
            styles.forEach((el)=>{
                style += el.innerHTML;
            })
        }

        return style || null;
    }

    /**
     * Applique le style passé en paramètre au HTML en inline
     * @param markup
     * @param css
     * @param erase
     */
    const transformStyleToInline = (markup: string, css: string, erase: boolean = false) => {

        if (!css) return markup;

        // Création de la div temporaire pour parser le dom
        const div = document.createElement("div");
        div.innerHTML = markup;

        // Transformation du css texte en json
        const styleJSON = toJSON(css.replaceAll("}",";}"));

        // Boucle sur tous les éléments du markup
        const allElements = div.querySelectorAll("*");
        allElements.forEach((el) => {

            // Récupération des styles existants de l'élément
            const currentStyle = el.getAttribute("style");
            const currentStyleJSON = currentStyle ? toJSON(currentStyle) : null;

            // Boucle sur tous les sélecteurs dispos
            const cssSelectors = Object.keys(styleJSON.children);
            cssSelectors.forEach((selector) => {
                // Si un sélecteur correspond à l'élément en cours on applique les styles
                if (el.matches(selector)) {

                    const styles = styleJSON.children[selector].attributes;
                    let styleStr = "";
                    Object.keys(styles).forEach((prop) => {
                        styleStr += styleStr.length ? ";" : "";

                        // Si le style  est déjà présent en inline et qu'on ne doit pas écraser, on prend cette valeur
                        if( !erase &&
                            currentStyleJSON &&
                            currentStyleJSON.attributes &&
                            currentStyleJSON.attributes[prop]
                        ){
                            styleStr += `${prop}:${currentStyleJSON.attributes[prop]}`;
                        }else{
                            styleStr += `${prop}:${styles[prop]}`;
                        }

                    });
                    el.setAttribute("style", styleStr);
                }
            })
        });

        // Retour du html mis à jour
        return div.innerHTML;
    }

    /**
     * Transform une chaine sous la form de rgb(X,X,X) en hexadecimal
     * @param rgb
     */
    function rgbToHex(rgb: string): string {
        // extract the red, green, and blue values from the string
        const values = rgb.match(/\d+/g);

        // convert the red, green, and blue values to hexadecimal
        const hexValues = values.map((value) => {
            const hexValue = parseInt(value, 10).toString(16);
            return hexValue.length === 1 ? '0' + hexValue : hexValue;
        });

        // combine the hexadecimal values into a single string
        return '#' + hexValues.join('');
    }

    /**
     * Nettoie le HTML
     *  - transforme les style en bloc en inline
     *  - retire les tags non voulus
     *  - retire les commentaires
     * @param input
     */
    const cleanContent = (input: string) => {
        const styles = getStyleFromMarkup(input);
        let cleanedContent = transformStyleToInline(input, styles);
        cleanedContent = cleanStyle(cleanedContent);
        cleanedContent = cleanHTMLRemovingSpecificTags(cleanedContent, ["div", "title", "html", "style", "meta"]);
        cleanedContent = removeHtmlComments(cleanedContent);
        cleanedContent = cleanedContent.replace(/id="[^"]*"/g, "");

        return cleanedContent;
    }

    return {
        cleanHTMLRemovingSpecificTags,
        cleanStyle,
        transformStyleToInline,
        getStyleFromMarkup,
        removeHtmlComments,
        cleanContent
    }
};