import { saveAs } from "file-saver";
import PizZipUtils from "pizzip/utils";
import {SITE_URL} from "../Constants/constants";
var Docxtemplater = require("docxtemplater");
var PizZip = require("pizzip");
var HTMLModule = require("docxtemplater-html-module");

// Define function to convert HTML string to docx document
async function convertHtmlToDocx(html: string,documentName: string) {
    PizZipUtils.getBinaryContent(
        `${process.env.PUBLIC_URL}/docs/empty.docx`,
        function (error, content) {
            if (error) {
                console.error(error);
                return;
            }

            var zip = new PizZip(content);
            var doc = new Docxtemplater(zip, {
                linebreaks: false,
                modules: [new HTMLModule({
                    linebreaks: false,
                    ignoreCssErrors: true,
                    styleSheet: `
         
                        p,table,th,td,h1,h2,h3,h4,h5,ul,li,span{
                            font-family: 'Arial','Helvetica';
                        }
                        h1{
                            font-size: 22px;
                        }
                        h2{
                            font-size: 20px;
                        }
                        h3{
                            font-size: 18px;
                        }
                        h4{
                            font-size: 16px;
                        }
                        h5{
                            font-size: 14px;
                        }
                        p,table,ul,li,span{
                            font-size: 11px;
                        }
                        h1,h2,h3,h4,h5,p,ul {
                            margin:0;
                        }
                        td{
                            padding: 5px;
                        }
                    `
                })],
            });

            doc.render({
                html,
            });
            var out = doc.getZip().generate({
                type: "blob",
                mimeType:
                    "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
            });
            saveAs(out, `${documentName}.docx`);
        }
    );
}


export const Export2Word = (html: string, documentName: string) => {
    convertHtmlToDocx(html,documentName)
}

