import React from "react";
import OIHeader from "../Components/Header.component";
import {Button, Col, Row} from "react-materialize";


const ConsentScreen = (props: {
    onConsent: () => void
}) => {

    return <>
        <OIHeader />

        <Row>
            <Col s={3}></Col>
            <Col s={6}>
                <h1>Accepter les conditions d'utilisation</h1>
                <p><strong>Pour continuer, veuillez accepter les conditions d'utilisation suivantes :</strong></p>
                <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Aliquid amet dicta nobis pariatur rem tempora voluptatem! Aliquid amet debitis deleniti dolor eveniet labore, laborum modi, nobis nostrum officiis quia quod.</p>
                <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Aliquid amet dicta nobis pariatur rem tempora voluptatem! Aliquid amet debitis deleniti dolor eveniet labore, laborum modi, nobis nostrum officiis quia quod.</p>
                <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Aliquid amet dicta nobis pariatur rem tempora voluptatem! Aliquid amet debitis deleniti dolor eveniet labore, laborum modi, nobis nostrum officiis quia quod.</p>
                <Button className={"cyan lighten-2"} small onClick={props.onConsent}>Accepter les conditions</Button>
            </Col>
            <Col s={3}></Col>
        </Row>
    </>
}

export default ConsentScreen;