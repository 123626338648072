import React from "react";
import {OIBilan} from "../Types/Bilan";
import {solid} from "@fortawesome/fontawesome-svg-core/import.macro";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Button, CollectionItem, Icon} from "react-materialize";

const BilanCard = (props: {
    bilan: OIBilan,
    onLoad: (id: string) => void
    onDelete: (id: string) => void
}) => {

    const {bilan} = props;

    const onLoad = () => {
        if (props.onLoad) props.onLoad(bilan.id);
    }

    const onDelete = () => {
        if (props.onDelete) props.onDelete(bilan.id);
    }

    return <CollectionItem>
        {bilan.title}
        <p>
            <Button onClick={onLoad}
                    className={"cyan lighten-2"}
                    style={{
                        marginRight: '5px'
                    }}
                    waves="light"
            ><Icon left>edit_document</Icon> Charger</Button>
            <Button onClick={onDelete}
                    className={"red lighten-1"}
                    waves="light"
            ><Icon left>delete</Icon> Supprimer</Button>
        </p>
    </CollectionItem>
};

export default BilanCard;