import React from "react";
import {OIInputTokenProps} from "../../Types/Fields";
import {TextInput} from "react-materialize";


const InputField = (props: OIInputTokenProps) => {
    return <div className={"oi-field-wrapper"}>
        <TextInput
            id={props.id}
            label={props.label}
            onChange={(e)=>props.onChange(props.id,e.currentTarget.value)}
            defaultValue={props.initialValue}
            placeholder={props.placeholder}
        />
    </div>
};

export default InputField;