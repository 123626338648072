import React, { useEffect, useMemo, useState } from "react";
import OIHeader from "../Components/Header.component";
import { Button, Col, Icon, Row } from "react-materialize";
import BilanTitle from "../Components/BilanTitle.component";
import OIEditorAside from "../Components/EditorAside.component";
import { useNavigate, useParams } from "react-router-dom";
import { OIBilanFieldsValues, OIBIlanLocalStorage } from "../Types/Bilan";
import { OIAlertMessage } from "../Types/Content";
import { BILAN_LOCALSTORAGE_KEY } from "../Constants/constants";
import moment from "moment";
import useLocalStorage from "../Hooks/useLocalStorage";
import JoditEditor, { Jodit } from "jodit-react";
import useApi from "../Hooks/useApi";
import OIModal from "../Components/Modal.component";
import useDelay from "../Hooks/useDelay";
import useTextInterpreter from "../Hooks/useTextInterpreter";
import { useHTML } from "../Hooks/useHTML";
import OIAlert from "../Components/Alert.component";
import { Export2Word } from "../Helpers/Export2Word";

const OIEditor = (props: {}) => {

    const navigate = useNavigate();
    const { delay } = useDelay();

    /**
     * DATAS
     */
    let { id } = useParams();
    const [bilanDatas, setBilanDatas] = useState<OIBilanFieldsValues>({
        title: "Sans titre",
        nomDocument: "",
        nom: "",
        patient: "",
        ilelle: "",
        percentile: "",
        ecartType: "",
    });
    const { set: setLocalStorage, get: getLocalStorage, setError } = useLocalStorage();
    const { interpretText } = useTextInterpreter();

    /**
     * HTML
     */
    const {
        cleanContent
    } = useHTML();

    /**
     * API
     */
    const { api, downloadFile } = useApi();
    useEffect(() => {
        api().get("/api/ping");
    }, []);

    /**
     * MODAL
     */
    const [modalDatas, setModalDatas] = useState<{
        title: string,
        text: string,
        actions: JSX.Element
    }>(null)

    /**
     * ALERT
     */
    const [alertMessage, setAlertMessage] = useState<OIAlertMessage>(null);

    /**
     * EDITEUR JODIT
     */
    let jodit: Jodit;
    const [content, setContent] = useState('');
    const config = useMemo<any>(
        () => ({
            readonly: false, // all options from https://xdsoft.net/jodit/doc/,
            showCharsCounter: false,
            showWordsCounter: false,
            showXPathInStatusbar: false,
            placeholder: 'Votre contenu...',

            defaultActionOnPaste: 'insert_as_html',
            askBeforePasteHTML: false,

            processPasteFromWord: true,
            askBeforePasteFromWord: false,
            defaultActionOnPasteFromWord: 'insert_clear_html',

            spellcheck: true,
            extraButtons: [],
            removeButtons: ['image', 'link'],
            cleanHTML: {
                denyTags: 'meta,title,script,img,a,hr,v:f,v:shape,v:shapetype,v:formulas,v:path,v:imagedata,o:lock',
                fillEmptyParagraph: false
            },
            buttons: [
                'source', '|',
                'bold',
                'strikethrough',
                'underline',
                'italic', '|',
                'ul',
                'ol', '|',
                'outdent', 'indent', '|',
                'fontsize',
                'brush',
                'paragraph', '|',
                'table', '|',
                'align', 'undo', 'redo', '|',
                'eraser',
                'copyformat', '|',
                'fullsize'
            ],
            buttonsXS: [],
        }),
        []
    );

    useEffect(() => {
        loadBilan();
    }, [id]);


    /**
     * Chargement du bilan.
     * Prend en compte la version enregistrée en local
     */
    const loadBilan = async () => {

        try {
            const localVersion: OIBIlanLocalStorage = getLocalStorage(BILAN_LOCALSTORAGE_KEY);

            if (typeof id !== "undefined") {

                const result = await api().get(`/api/templates/${id}`);

                if (result.data) {
                    let proposeLoadFromLocal = false;
                    if (localVersion && localVersion.id === id) {
                        const dateResult = moment(result.data.data.updated_at);
                        const dateLocal = moment(localVersion.date);
                        proposeLoadFromLocal = dateLocal.diff(dateResult) > 0;
                    }

                    // Proposition de charger la version auto saved plus récente
                    if (proposeLoadFromLocal) {
                        setModalDatas({
                            title: "Version plus récente",
                            text: "Une version enregistrée automatiquement est plus récente. Voulez-vous la charger ?",
                            actions: <>
                                <Button onClick={() => {
                                    setBilanDatas({
                                        ...bilanDatas,
                                        title: result.data.data.title
                                    });
                                    setContent(result.data.data.content);
                                    setModalDatas(null);
                                }} style={{ marginRight: 20 }} flat>Non charger la version originale</Button>
                                <Button onClick={() => {
                                    setBilanDatas({
                                        ...bilanDatas,
                                        title: localVersion.title
                                    });
                                    setContent(localVersion.content);
                                    setModalDatas(null);
                                }} className={"cyan lighten-2"}>Charger</Button>
                            </>
                        });
                    } else {
                        setBilanDatas({ ...bilanDatas, title: result.data.data.title });
                        setContent(result.data.data.content);
                    }
                }
            } else {
                if (!localVersion) return null;

                setModalDatas({
                    title: "Version enregistrée",
                    text: "Une version enregistrée automatiquement est disponible. Voulez-vous la charger ?",
                    actions: <>
                        <Button onClick={() => {
                            setModalDatas(null);
                        }} style={{ marginRight: 20 }} flat>Annuler</Button>
                        <Button onClick={() => {
                            setBilanDatas({ ...bilanDatas, title: localVersion.title });
                            setContent(localVersion.content);
                            setModalDatas(null);
                        }} className={"cyan lighten-2"}>Charger</Button>
                    </>
                });
            }

        } catch (e) {
            let message = "Une erreur est survenue.";
            if (e.response && e.response.status === 404) {
                message = "Cette trame n'existe pas."
            }
            setError(message);
            return navigate("/");
        }
    }
    const saveLocal = (newContent: string, newTitle: string) => {
        setLocalStorage(BILAN_LOCALSTORAGE_KEY, {
            id,
            title: newTitle,
            content: newContent,
            date: moment().toISOString()
        });
    }
    const onTitleChange = async (title: string) => {
        setBilanDatas({ ...bilanDatas, title });
        await delay(200);
        saveLocal(content, title);
    }
    const onSave = async () => {

        // On attend que le contenu soit à jour au blur
        await delay(200);

        const url = "/api/templates";

        const data = {
            content,
            title: bilanDatas.title
        };

        let result;
        try {
            if (typeof id !== "undefined") {
                await api().put(`${url}/${id}`, data);
                setAlertMessage({
                    message: "Trame enregistrée",
                    type: "success"
                })
            } else {
                result = await api().post(url, data);
                if (result.data) {
                    navigate(`/editor/${result.data.data.id}`);
                }
            }
        } catch (e) {
            setAlertMessage({
                message: "Une erreur est survenue",
                type: "error"
            });
        }
    }

    const onFieldValueChange = (id: string, value: string) => {
        const newBilanDatas = { ...bilanDatas };
        newBilanDatas[id as any] = value;
        setBilanDatas(newBilanDatas);
    }

    const onExport = async (type: "pdf" | "word") => {

        if (!jodit || (jodit && !jodit.selection)) return null;

        // On attend que le blur des éventuels docus se termine
        await delay(500);

        // Application des styles qui sont dans une balise si nécessaire
        let transformedHtml = cleanContent(content);
        transformedHtml = interpretText(transformedHtml, bilanDatas);
        // Nom du document
        const documentName = bilanDatas.nomDocument || 'bilan';

        if (type === "pdf") {
            const result = await api().post("/api/templates/pdf", { html: transformedHtml }, { responseType: 'blob' });
            downloadFile(result.data, documentName + ".pdf", "application/pdf");

        } else {
            const buffer = await Export2Word(transformedHtml, documentName);
        }

        return;
    }

    const onBlur = (newContent: string) => {

        let cleanedContent = cleanContent(newContent);

        setContent(cleanedContent);
        saveLocal(cleanedContent, bilanDatas.title);
    }

    const onChange = (newContent: string) => {
        if (newContent.search('<style') !== -1 || newContent.search('"Mso') !== -1) {
            let cleanedContent = cleanContent(newContent);

            setContent(cleanedContent);
            saveLocal(cleanedContent, bilanDatas.title);
        }
    }



    const onAddToken = (token: string) => {
        if (!jodit || (jodit && !jodit.selection)) return null;


        jodit.selection.insertHTML(token);
    }


    return <>
        <OIHeader />

        {alertMessage &&
            <OIAlert message={alertMessage.message} type={alertMessage.type} onHide={() => setAlertMessage(null)} />}

        <header className={"oi-editor-header"}>
            <Button floating tooltip={"Retour aux trames"} style={{ marginRight: 20 }} className={"cyan lighten-2"}
                onClick={() => navigate("/")}><Icon>arrow_back</Icon></Button>
            <BilanTitle initialValue={bilanDatas.title || "Sans titre"} onBlur={onTitleChange} />
        </header>
        <Row>
            <Col s={9}>
                <div className={".browser-default"}>
                    <JoditEditor
                        ref={refObj => {
                            jodit = refObj;
                        }}
                        value={content}
                        config={config}
                        onChange={newContent => onChange(newContent)}
                        onBlur={newContent => onBlur(newContent)}
                    />
                </div>
            </Col>
            <Col s={3} className={"oi-editor-aside"}>
                <OIEditorAside
                    editorContent={content}
                    onSave={onSave}
                    bilanDatas={bilanDatas}
                    onAddToken={onAddToken}
                    onFieldValueChange={onFieldValueChange}
                    onExport={onExport} />
            </Col>
        </Row>

        {modalDatas && <>
            <OIModal actions={modalDatas.actions} title={modalDatas.title} text={modalDatas.text} isOpen={true} />
        </>}
    </>
};


export default OIEditor;