import React from "react";


const OIModal = (props:{
    isOpen: boolean,
    title: string,
    text: string,
    actions: JSX.Element
}) => {


    return <div className={`oi-modal ${props.isOpen ? '-open' : ''}`}>
        <div className="overlay"></div>
        <div className="content">
            <div className="modal open">
                <div className="modal-content">
                    <h4>{props.title}</h4>
                    <p>{props.text}</p>
                </div>
                <div className="modal-footer">
                    {props.actions}
                </div>
            </div>
        </div>
    </div>
}

export default OIModal;