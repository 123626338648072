const useDelay = () => {

    const delay = (milliseconds) => {
        return new Promise(resolve => {
            setTimeout(resolve, milliseconds);
        });
    }

    return {delay};
}

export  default  useDelay;