import React, {useEffect, useState} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {solid} from "@fortawesome/fontawesome-svg-core/import.macro";

const OIAlert = (props: {
    message: string,
    type: "error"|"success",
    onHide: () => void
}) => {

    const getColorFromType = () => {
        switch (props.type){
            case "error": return "red lighten-2";
            case "success": return "green lighten-2";
        }

        return "";
    }

    return <div className={`oi-alert card-panel ${getColorFromType()}`}>

        <div className="message">
            {props.message}
        </div>

        <button className="icon g-btn -light" onClick={()=> props.onHide()}>
            <FontAwesomeIcon icon={solid('xmark')} />
        </button>
    </div>;
}

export default OIAlert;
