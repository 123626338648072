import React, {useState} from "react";
import {Button, Col, Row} from "react-materialize";
import {OITokenType} from "../../Types/Fields";
import OIModal from "../Modal.component";

const AddTokenButton = (props: {
    id: string,
    label?: string,
    type: OITokenType,
    onAddToken: (token: string) => void
}) => {

    /**
     * MODAL
     */
    const [modalDatas, setModalDatas] = useState<{
        title: string,
        text: string,
        actions: JSX.Element
    }>(null)

    const onAddToken = () => {

        if(props.type === "textToken"){
            setModalDatas({
                title: "Gestion de la majuscule",
                text: "Voulez-vous que la première lettre soit en majuscule (pour cette insertion uniquement) ?",
                actions: <>
                    <Button onClick={() => {
                        props.onAddToken(generateToken(false));
                        setModalDatas(null);
                    }} style={{marginRight: 20}} flat>Non</Button>
                    <Button onClick={() => {
                        props.onAddToken(generateToken(true));
                        setModalDatas(null);
                    }} className={"cyan lighten-2"}>Oui</Button>
                </>
            });
        }else{
            props.onAddToken(generateToken(false));
        }
    }

    const generateToken = (caps: boolean = false) => {
        return `[[${props.id}${caps?'+':''}]]`;
    }

    return <Row>
        <Col s={2}>
            <Button small onClick={onAddToken} className={"cyan lighten-2"}>+</Button>
        </Col>
        <Col s={10}>
            {props.label && <div>{props.label}</div>}
        </Col>
        {modalDatas && <>
            <OIModal actions={modalDatas.actions} title={modalDatas.title} text={modalDatas.text} isOpen={true}/>
        </>}
    </Row>
};

export default AddTokenButton;