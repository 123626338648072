import React from "react";


const BilanTitle = (props: {
    initialValue: string,
    onBlur : (content: string) => void
}) => {

    return <h1 contentEditable={true} onBlur={(e)=>props.onBlur(e.target.textContent)} suppressContentEditableWarning={true} className={"oi-editor-title"}>{props.initialValue}</h1>;

};

export default BilanTitle;