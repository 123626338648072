import React from 'react';
import './assets/scss/main.scss';
import {
  HashRouter as Router,
  Route, Routes
} from "react-router-dom";
import OIBilans from "./Pages/Bilans.screen";
import OILogin from "./Pages/Login.screen";
import {PrivateRoute} from "./Components/PrivateRoute.component";
import OIEditor from "./Pages/Editor.screen";

function App() {

  return <Router basename={'/'}>
    <Routes>
      <Route path="/signin" element={<OILogin/>} />
      <Route path="/" element={<PrivateRoute><OIBilans/></PrivateRoute>} />
      <Route path="/editor/:id" element={<PrivateRoute><OIEditor/></PrivateRoute>} />
      <Route path="/editor" element={<PrivateRoute><OIEditor/></PrivateRoute>} />
    </Routes>
  </Router>;
}

export default App;
