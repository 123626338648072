import React, {useEffect, useState} from "react";
import useApi from "../Hooks/useApi";
import {OIUser} from "../Types/User";
import {Dropdown, Icon, Navbar, NavItem} from "react-materialize";
import LogoBlanc from "../assets/images/logoBlanc.png";
import {MATRAM_URL} from "../Constants/constants";

const OIHeader = () => {

    const {getUser,logOut} = useApi();
    const [user, setUser] = useState<OIUser>()

    useEffect(()=>{
       setUser(getUser());
    },[]);

    return <div>
        <Navbar
            alignLinks="right"
            className={"cyan lighten-2"}
            brand={<a className="brand-logo" href="#"><img src={LogoBlanc} /></a>}
            id="mobile-nav"
            menuIcon={<Icon>menu</Icon>}
        >

            <NavItem href={MATRAM_URL}>
                Retour au site
            </NavItem>
            {user && <>

                <Dropdown
                    id="Dropdown_14"
                    trigger={<a href="#!">Bonjour {user.name}{' '}<Icon right>account_circle</Icon></a>}
                >
                    <a href="#" onClick={logOut} className={"cyan-text text-lighten-2"}>
                        Se déconnecter
                    </a>
                </Dropdown>
            </>}
        </Navbar>

    </div>
}

export default OIHeader;