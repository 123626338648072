import React from "react";
import InputField from "./Fields/InputField.component";
import AddTokenButton from "./Fields/AddTokenButton.component";
import ValueTokenField from "./Fields/ValueTokenField.component";
import {OIBilanFieldsValues} from "../Types/Bilan";
import {Button, Col, Icon, Row} from "react-materialize";
import {OITokenFieldData} from "../Types/Fields";

const OIEditorAside = (props: {
    editorContent: string,
    onSave : () => void,
    bilanDatas: OIBilanFieldsValues,
    onAddToken: (token: string) => void,
    onFieldValueChange: (id:string, content: string) => void,
    onExport: (type: "pdf" | "word") => void
}) => {

    const fieldsData: OITokenFieldData[] = [
        {
            id: "nomDocument",
            label: "Document",
            placeholder: "Ex: bilan_leo_2022",
            type: "text",
            asToken: false
        },
        {
            id: "nom",
            label: "Nom",
            placeholder: "Michel Dupond",
            type: "nameToken",
            asToken: true
        },
        {
            id: "patient",
            label: "Patient.e",
            placeholder: "le patient",
            type: "textToken",
            asToken: true
        },
        {
            id: "ilelle",
            label: "Il / Elle / Iel",
            placeholder: "il",
            type: "textToken",
            asToken: true
        },
        {
            id: "percentile",
            label: "Percentile",
            placeholder: "15",
            type: "valueToken",
            asToken: true
        },
        {
            id: "ecartType",
            label: "Ecart type",
            placeholder: "Ex: -0.9",
            type: "valueToken",
            asToken: true
        },
        {
            id: "moyenne",
            label: "Moyenne",
            type: "noValueToken",
            asToken: true
        },
        {
            id: "zoneFragilite",
            label: "Zone de fragilité",
            type: "noValueToken",
            asToken: true
        },
        {
            id: "zonePathologique",
            label: "Zone pathologique",
            type: "noValueToken",
            asToken: true
        },
    ];

    const getField = (fieldData: OITokenFieldData) => {
        switch (fieldData.type){
            case "text":
                return <Row>
                    <Col>
                        <InputField id={fieldData.id} label={fieldData.label} placeholder={fieldData.placeholder || ""} initialValue={props.bilanDatas[fieldData.id]} onChange={props.onFieldValueChange}/>
                    </Col>
                </Row>

            case "nameToken":
            case "textToken":
                return <Row>
                    <Col s={2}>
                        <AddTokenButton id={fieldData.id} onAddToken={props.onAddToken} type={fieldData.type}/>
                    </Col>
                    <Col s={10}>
                        <InputField id={fieldData.id} label={fieldData.label} placeholder={fieldData.placeholder || ""} initialValue={props.bilanDatas[fieldData.id]} onChange={props.onFieldValueChange}/>
                    </Col>
                </Row>

            case "valueToken":
                return <ValueTokenField id={fieldData.id} label={fieldData.label} onAddToken={props.onAddToken} initialValue={props.bilanDatas[fieldData.id]} placeholder={fieldData.placeholder || ""} onChange={props.onFieldValueChange}/>

            case "noValueToken":
                return <AddTokenButton id={fieldData.id} label={fieldData.label} onAddToken={props.onAddToken}  type={fieldData.type}/>
        }

        return null;
    }

    return <div className="content">
        <div className="oi-field-row -mb">
            <Button small className={"green lighten-2"} onClick={(e)=>props.onSave()} tooltip={"Enregistrer la trame"} style={{marginRight: 5}}>
                <Icon left>save</Icon>
                Enregistrer la trame
            </Button>
            <Button small className={"blue lighten-2"} onClick={(e)=>props.onExport("word")} tooltip={"Export Word"} style={{marginRight: 5}}>
                <Icon>description</Icon>
            </Button>
            <Button small className={"blue lighten-2"} onClick={(e)=>props.onExport("pdf")} tooltip={"Export Pdf"} style={{marginRight: 5}}>
                <Icon>picture_as_pdf</Icon>
            </Button>
        </div>

        {fieldsData.map((fd,k) => <div key={k} className={"oi-field-row"}>
            {getField(fd)}
        </div>)}
    </div>
}

export default OIEditorAside;